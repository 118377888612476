<template>
  <div class="w-full h-full pr-20">
    <popupConfirm
      v-if="activeConfirm"
      :activation="activeConfirm"
      :donne="response"
      @oga="retourConfirm"
    />
    <div class="text-left text-c18 font-c4">
      Agence
    </div>

    <div class="mt-4">
      <multiselect
        v-if="agence !== null"
        :value-t="agence"
        :icon="icons.ic_agence"
        :option="allAgence"
        height="60px"
        @info="retourAgence"
      />
    </div>

    <div
      class="mt-16 h-60 bg-oho-blue flex justify-center items-center text-c18 text-white rounded-10 cursor-pointer"
      @click="continuer"
    >
      <easy-spinner
        v-if="charge"
        type="dots"
        size="30"
      />
      <div v-if="!charge">
        Connexion
      </div>
    </div>
  </div>
</template>

<script>
import ic_agence from '../../../assets/icons/agence.svg'
import multiselect from '../../../component/helper/form/multiselect'
import popupConfirm from '../../../component/popup/confirmAgence'
import apiroutes from '@/router/api-routes'
import http from '@/plugins/https'

export default {
  name: "Index",
  components: {
    multiselect,
    popupConfirm
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },
    token: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      charge: false,
      agence: null,
      icons: {
        ic_agence
      },
      myAgence: null,
      allAgence: [],
      activeConfirm: false,
      response: {
        error: false,
        agence: ''
      },
      user: null,
      agency: []
    }
  },

  created(){
    this.user = this.donnes
    if (this.user.sessionAgency === ''){
      window.location.replace(window.location.origin + '/dashboard');
    }
    this.getAllAgence()
  },

  methods: {
    getAllAgence () {
      http.get(apiroutes.baseURL + apiroutes.allAgency)
          .then(response => {
            this.agency = response
            for (let item in response) {
              this.allAgence.push(response[item].label)
              if (this.user.sessionAgency === response[item].id){
                this.agence = response[item].label
                this.myAgence = response[item].label
                this.swichAgence()
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
    },

    retourAgence(answer){
      this.agence = answer
    },

    retourConfirm(answer){
      this.activeConfirm = answer
      if (answer === true && this.agence !== this.myAgence){
        this.charge = true
        this.swichAgence()
      }
      if (answer === true && this.agence === this.myAgence){
        this.$store.dispatch('saveToken', this.token)
        window.location.replace(window.location.origin + '/commandes');
      }
    },

    swichAgence(){
      this.activeConfirm = false
      let id = null

      for (let item in this.agency){
        if(this.agency[item].label === this.agence) {
          id = this.agency[item].id
        }
      }

      http.post(apiroutes.baseURL + apiroutes.swich, {
        agency: id
      }, {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      })
          .then(response => {
            this.charge = false
            this.$store.dispatch('saveToken', response.token)
            window.location.replace(window.location.origin + '/commandes');
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = 'Désolé! Nous n\'avons pas pu traiter votre requête. Veuillez ressayez'
          })
    },

    continuer(){
      this.response.agence = this.agence
      this.$store.dispatch('saveAgence', this.agence)
      if (this.agence !== this.myAgence) {
        this.response.error = true
      }
      this.activeConfirm = true
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";

.svg-icon{
  fill: white;
}
</style>
